import React, { useEffect } from 'react'
import styled from 'styled-components';
import * as color from '../css/ColorVariables';

import SortiLogo from '../asset/img/sortiLogo.png';

import SortiButton from './SortiButton';

const Wrapper = styled.div`
    display : flex;
    width : 86%;
    position : fixed;
    z-index : 1000;
    font-family : suitBold;
    align-items : center;
    justify-content : space-between;
    padding : 5% 7% 1% 7%;
    background:${props => props.top ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.7)'}; 
    backdrop-filter:  blur(30px);
    transform : ${props => props.top ? 'translateY(0)' : 'translateY(-35%)'};
    transition : 0.5s;
    box-shadow: ${props => props.top ? '' : '0px 1px 20px #D0E3DC'};
    & > img {
        height : 30px;
        cursor : pointer;
    }
    & > div {
        display : flex;
        align-items : center;
        column-gap : 60px;
        & > p {
            color : ${color.mainColor};
            cursor : pointer;
        }
    }
`

function MenuBar(props) {

    const goToSignUp = () => {
        window.location.href = '/signup';
    }

    const goToLogin = () => {
        window.location.href = '/login';
    }

    const goToMain = () => {
        window.location.href='/';
    }

  return (
    <Wrapper top={props.top}>
        <img src={SortiLogo} alt={''} onClick={goToMain}/>
        <div>
            <p onClick={goToSignUp}>회원가입</p>
            <SortiButton onClick={goToLogin} text={'로그인'}/>
        </div>
    </Wrapper>
  )
}

export default MenuBar