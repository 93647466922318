import React from 'react'
import styled , {keyframes} from 'styled-components'
import * as color from '../css/ColorVariables';

import DownArrow from '../asset/img/downArrow.png';
import IconGroup from '../asset/img/iconGroup.png';

import Background from '../asset/img/urlBack.png';
import BackgroundMobile from '../asset/img/BackMobile.png';

const width = window.innerWidth;
const isMobile = width < 600;

const urlFontSize = 24;

const ArrowAnimation = keyframes`
    0%{
        transform: translate(0 , 0);
    }
    50%{
        transform: translate(0 , 10px);
    }
    100%{
        transform: translate(0 , 0);
    }
`

const ShortUrlDescriptionWrapper = styled.div`
    position : relative;
    background : url(${isMobile ? BackgroundMobile : Background});
    z-index : 10;
    background-size : cover;
    background-repeat : no-repeat;
    height : 100vh;
    & > div:first-child{
        display : flex;
        justify-content : center;
        position : absolute;
        width : 100%;
        z-index : -1;
        font-family : suitExBold;
        font-size : ${isMobile ? '26px' : '32px'};
        left : 50%;
        right : 50%;
        top : 40%;
        bottom : 50%;
        transform: translate(-50%, -50%);
        & > p {
            display : inline-block;
            width : ${isMobile ? '80%' : '50%'};
            position : absolute;
            text-align : center;
            background: linear-gradient(180deg, #DDD 0%, rgba(236, 239, 238, 0) 100%);
            -webkit-background-clip : text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            line-height: 40px;
            word-wrap: break-word;
        }
    }
    & > div:nth-child(2){
        display : flex;
        position : absolute;
        width : 80%;
        left : 50%;
        right : 50%;
        top : 50%;
        bottom : 40%;
        transform: translate(-50%, -50%);
        flex-direction : column;
        row-gap : 50px;
        align-items : center;
        & > p {
            font-family : suitExBold;
            font-size : ${isMobile ? '24px' : '40px'};
            text-align : center;
            line-height: ${isMobile ? '30px' : '50px'};
            & > strong {
            color : ${color.mainColor};
            font-weight : normal;
            }
        }

        & > img {
            width : 50px;
            animation : ${ArrowAnimation} 1.5s  infinite linear;
        }
        & > div {
            display : flex;
            align-items : center;
            font-family : suitRegular;
            font-size : ${isMobile ? urlFontSize-4 : urlFontSize};
            border : 1px solid ${color.brightgrayColor};
            border-radius : 15px;
            padding : ${isMobile ? '16px 23px 14px 23px' : '22px 23px 20px 23px'};
            background-color : white;
            & > img { 
                height : ${isMobile ? urlFontSize-8 : urlFontSize-5}px;
                padding-bottom : 3px;
            }
        }
    }
`

function ShortUrlDescription() {
  return (
    <ShortUrlDescriptionWrapper>
        <div>
            <p>http://growtothenextlevelwithsortielabmacallannickjacepigmesageseokansnorlax</p>
        </div>
        <div>
            <p>길고 복잡한 URL, <br/> <strong>짧고 간단하게</strong> 줄여보세요</p>
            <img src={DownArrow} alt={''}/>
            <div>
                <p>http://sorti.me/</p>
                <img src={IconGroup} alt={''}/>
            </div>
        </div>
    </ShortUrlDescriptionWrapper>
  )
}

export default ShortUrlDescription