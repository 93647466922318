import React from 'react';
import styled , {keyframes} from 'styled-components';

import Graph from '../asset/img/graph.png';
import Graphbox from '../asset/img/graphbox.png';

const width = window.innerWidth;
const isMobile = width < 600;

const Wrapper = styled.div`
    position : relative;
    row-gap : -1000px;
    height : 100vh;
    & > div {
        display :flex;
        position : absolute;
        flex-direction : column;
        align-items : center;
        top : ${isMobile ? '45%' : '40%'};
        bottom : 50%;
        transform: translate( 0, -50%);
        & > p {
            position : absolute;
            font-family: suitExBold;
            font-size : ${isMobile ? '24px' : '40px'} ;
        }
        & > img {
            margin-top : 100px;
            width : ${isMobile ? '120%' : '100%'};
        }
    }
`

const BoxAnimation = keyframes`
    0%{
        transform: translate(0 , 0);
    }
    50%{
        transform: translate(0 , 10px);
    }
    100%{
        transform: translate(0 , 0);
    }
`

const Img = styled.img`
    position : absolute;
    top : ${isMobile ? '-100%' : '80%'};
    width : ${isMobile ? '90% !important' : '40% !important'};
    animation : ${BoxAnimation} 1.5s  infinite linear;
`

function LinkReportContainer() {
  return (
    <Wrapper>
        <div>
            <p>내 링크 얼마나 클릭됐을까?</p>
            <img src={Graph} alt="" />
            <Img src={Graphbox} alt=""/>
        </div>
    </Wrapper>
  )
}

export default LinkReportContainer