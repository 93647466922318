import React , {useEffect} from 'react'
import styled from 'styled-components'
import * as color from '../css/ColorVariables';

import IconGroup from '../asset/img/iconGroup.png';

import ToggleButton from './ToggleButton';

const width = window.innerWidth;
const isMobile = width < 600;

const urlFontSize = 24;

const MySortiContainerWrapper = styled.div`
    display : flex;
    flex-direction : ${isMobile ? 'column' : 'row'};
    justify-content : center;
    align-items : center;
    height : 100vh;
    padding : ${isMobile ? '0 5%' : '0 10%'};
    column-gap:  7%;
    row-gap : ${isMobile ? '50px' : '0'};
    background-color: ${color.whiteColor};
    & > div > p > strong {
        font-weight : normal;
        color : ${color.mainColor}
    }
    & > div:first-child{
        display : flex;
        flex-direction : column;
        row-gap : ${isMobile ? '30px' : '60px'}; 
        & > p {
            font-family : suitExBold;
            font-size : ${isMobile ? '24px' : '40px'};
            line-height: ${isMobile ? '30px' : '50px'};
        }
        & > span{
            font-family : suitMedium;
            font-size : ${isMobile ? '14px' : '20px'};
            line-height : ${isMobile ? '18px' : '25px'};
            color : ${color.darkgrayColor}
        }
    }
    & > div:nth-child(2){
        display : flex;
        width : ${isMobile ? '100%' : '50%'};
        flex-direction : column;
        justify-content : center;
        align-items : ${isMobile ? 'center' : 'flex-start'};
        row-gap : 30px;
        & > div:nth-child(1){
            display : flex;
            background-color : white;
            justify-content : space-between;
            align-items : center;
            width : ${isMobile ? '47%' : ''};
            font-family : suitRegular;
            font-size : ${isMobile ? urlFontSize-8 : urlFontSize}px;
            color : ${color.mainColor};
            border : 1.5px solid ${props => props.toggle ?  color.mainColor : color.brightgrayColor};
            border-radius : 15px;
            padding : ${isMobile ? '11px 23px 9px 23px' : '17px 23px 15px 23px'};
            box-shadow: 0px 5px 15px #D0E3DC;
            column-gap: 10%; 
            transition : 0.5s;
            & > p {
                width :  ${isMobile ? '280px' : '150px'};
            }
        }
        & > div:nth-child(2){
            display : flex;
            background-color : white;
            align-items : center;
            font-family : suitRegular;
            font-size : ${isMobile ? urlFontSize-8 : urlFontSize}px;
            border : 1.5px solid ${color.brightgrayColor};
            border-radius : 15px;
            padding : ${isMobile ? '17px 20px 15px 20px' : '22px 23px 20px 23px'};
            box-shadow: 0px 5px 15px #D0E3DC;
            transition : width 0.5s;
            & > img { 
                height : ${isMobile ? urlFontSize-12 : urlFontSize-8}px;
            }
            & > p > strong {
                font-weight : normal;
                color : ${color.mainColor};
            }
        }
    }
`

function MySortiContainer() {

    const [toggleOnOff , setToggleOnOff] = React.useState(false);

    const handleToggleOnOff = () => {
        setToggleOnOff(!toggleOnOff);
    }

    useEffect(()=>{
        const timer = setInterval(()=>{
            handleToggleOnOff();
        },1500);

        return () => clearInterval(timer);
    })

  return (
    <MySortiContainerWrapper toggle={toggleOnOff}>
        <div>
            <p><strong>MY SORTI</strong>를 통해<br/> 남들과는 다른 <strong>나만의 URL</strong>로</p>
            <span>링크에 나만의 키워드를 등록하여 손쉽게 분류하고<br/> 관리할 수 있습니다.</span>
        </div>
        <div>
            <div>
                <p>MY SORTI</p>
                <ToggleButton toggle={toggleOnOff}/>
            </div>
            <div>
                <p>http://sorti.me/{toggleOnOff ? <><strong>MY SORTI</strong><span>/</span></>: ''}</p>
                <img src={IconGroup} alt={''}/>
            </div>
        </div>
    </MySortiContainerWrapper>
  )
}

export default MySortiContainer