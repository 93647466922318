import React from 'react'
import styled from 'styled-components';
import * as color from '../css/ColorVariables';
import axios from 'axios';

import SocialLoginButton from '../components/SocialLoginButton';

import Google from '../asset/img/google.png';
import Kakao from '../asset/img/kakao.png';

const width = window.innerWidth;
const isMobile = width < 600;

const Wrapper = styled.div`
    background-color : ${color.mainColor};
    height : 100vh;
    min-width : ${isMobile ? '300px' : '1100px'} ;
    & > div {
        position : absolute;
        left : 50%;
        right : 50%;
        top : 50%;
        bottom : 50%;
        transform: translate(-50%, -40%);
        width : ${isMobile ? '10%' : '55%'};
        min-width : ${isMobile ? '350px' : '600px'} ;
        height : fit-content;
        padding : 7.5% 0 ;
        border-radius : 10px;
        text-align : center;
        background-color : white;
        & > p:first-child{
            font-family : suitExBold;
            font-size : ${isMobile ? '20px' : '40px'};
        }  
        & > p:nth-child(2){
            font-family : suitMedium;
            margin : auto;
            font-size : ${isMobile ? '14px' : '20px'};
            line-height : ${isMobile ? '18px' : '25px'};
            width : 80%;
            padding : 5% 0;
        } 
        & > p:last-child{
            font-family : suitMedium;
            margin-top : 30px;
            line-height : 160%;
            font-size : ${isMobile ? '12px' : ''};
            & > a {
                color : ${color.darkgrayColor};
                text-decoration : underline;
            }
        }
        & > p > strong {
            color : ${color.mainColor};
            font-weight : normal;
        }
        & > div {
            display : flex;
            width : 100%;
            flex-direction : column;
            align-items : center;
            row-gap : 15px;
        }
    }
`

function Login() {

    const onClickGoogle = () => {
        window.location.href= 'http://sorti.me:8081/sorti/api/user/login/google';

    }

    const onClickKakao = () => {
        window.location.href = 'http://sorti.me:8081/oauth2/authorization/kakao';
    }

  return (
    <Wrapper>
        <div>
            <p><strong>SORTI</strong>에 로그인</p>
            <p>SORTI 서비스 이용을 위해 아래의 소셜 계정으로 로그인을 해주세요!</p>
            <div>
                <SocialLoginButton
                    border={`1px solid ${color.brightgrayColor}`}
                    backgroundColor={'white'}
                    text={'Google로 시작하기'}
                    onClick={onClickGoogle}
                    img={Google}
                />
                <SocialLoginButton
                    border={'none'}
                    backgroundColor={'#FEE500'}
                    text={'카카오로 시작하기'}
                    onClick={onClickKakao}
                    img={Kakao}
                />
            </div>
            <p>SORTI에 가입하시면 <a>어쩌구 저쩌구 정책</a>과 <a>저쩌구 어쩌구 정책</a>에<br/>동의하는 것으로 간주합니다.</p>
        </div>
    </Wrapper>
  )
}

export default Login