// Color 변수
export const mainColor = '#00CE7C';

export const hoverSelectedButton = '#07EB90';

export const backgroundColor = '#F1F7F4';

export const brightgrayColor = '#D0E3DC';

export const darkgrayColor = '#A0B1AB';

export const subViolet = '#B287F8';

export const subBluish = '#756BF1';

export const subSkyBlue = '#65C8F2';

export const subRed = '#FF5353';

export const whiteColor = '#F1F7F4';

export const mainColorShadow = '4px 4px 25px rgba(0, 206, 124, 0.4)';