import React , {useRef , useEffect} from 'react'
import styled from 'styled-components';

import BannerContainer from '../components/BannerContainer';
import MySortiContainer from '../components/MySortiContainer';
import LinkReportContainer from '../components/LinkReportContainer';
import ShortUrlDescription from '../components/ShortUrlDescription';
import ExtensionContainer from '../components/ExtensionContainer';
import StartSortiContainer from '../components/StartSortiContainer';
import Footer from '../components/Footer';

const width = window.innerWidth;
const isMobile = width < 600;

const Wrapper = styled.div`
  height : 100vh;
  overflow-y: auto;
  overflow-x : hidden;
  min-width : ${isMobile ? '300px' : '1000px'}; 
`

function Main(props) {

  const outerDivRef = useRef();

  useEffect(() => {

    const wheelHandler = (e) => {
      e.preventDefault();
      const { deltaY } = e;
      const { scrollTop } = outerDivRef.current; // 스크롤 위쪽 끝부분 위치
      const pageHeight = window.innerHeight; // 화면 세로길이, 100vh와 같습니다.
    
      if (deltaY > 0) {
        // 스크롤 내릴 때
        if (scrollTop >= 0 && scrollTop < pageHeight) {
          //현재 1페이지
          outerDivRef.current.scrollTo({
            top: pageHeight,
            left: 0,
            behavior: "smooth",
          });
          props.setMenu(false);
        } else if (scrollTop >= pageHeight && scrollTop < pageHeight * 2) {
          //현재 2페이지
          outerDivRef.current.scrollTo({
            top: pageHeight * 2,
            left: 0,
            behavior: "smooth",
          });
          props.setMenu(false);
        } else if (scrollTop >= pageHeight && scrollTop < pageHeight * 3) {
          //현재 3페이지
          console.log("현재 3페이지, down");
          outerDivRef.current.scrollTo({
            top: pageHeight * 3,
            left: 0,
            behavior: "smooth",
          });
          props.setMenu(false);
        } else if (scrollTop >= pageHeight && scrollTop < pageHeight * 4) {
          //현재 4페이지
          outerDivRef.current.scrollTo({
            top: pageHeight * 4,
            left: 0,
            behavior: "smooth",
          });
          props.setMenu(false);
        } else if (scrollTop >= pageHeight && scrollTop < pageHeight * 5) {
          //현재 5페이지
          outerDivRef.current.scrollTo({
            top: pageHeight * 5,
            left: 0,
            behavior: "smooth",
          });
          props.setMenu(false);
        } else if (scrollTop >= pageHeight && scrollTop < pageHeight * 6) {
          //현재 6페이지
          outerDivRef.current.scrollTo({
            top: pageHeight * 6.4,
            left: 0,
            behavior: "smooth",
          });
          console.log(scrollTop)
          props.setMenu(false);
        } else {
          // 현재 7페이지
          outerDivRef.current.scrollTo({
            top: pageHeight * 7,
            left: 0,
            behavior: "smooth",
          });
          props.setMenu(false);
        }
      } else {
        // 스크롤 올릴 때
        if (scrollTop >= 0 && scrollTop < pageHeight) {
          //현재 1페이지
          outerDivRef.current.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          props.setMenu(true);
        } else if (scrollTop >= pageHeight && scrollTop < pageHeight * 2) {
          //현재 2페이지
          outerDivRef.current.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          props.setMenu(true);
        } else if (scrollTop >= pageHeight && scrollTop < pageHeight * 3) {
          //현재 3페이지
          outerDivRef.current.scrollTo({
            top: pageHeight,
            left: 0,
            behavior: "smooth",
          });
          props.setMenu(false);
        } else if (scrollTop >= pageHeight && scrollTop < pageHeight * 4) {
          //현재 4페이지
          outerDivRef.current.scrollTo({
            top: pageHeight * 2,
            left: 0,
            behavior: "smooth",
          });
          props.setMenu(false);
        } else if (scrollTop >= pageHeight && scrollTop < pageHeight * 5) {
          //현재 5페이지
          outerDivRef.current.scrollTo({
            top: pageHeight * 3,
            left: 0,
            behavior: "smooth",
          });
          props.setMenu(false);
        } else if (scrollTop >= pageHeight && scrollTop < pageHeight * 5.2) {
          //현재 6페이지
          outerDivRef.current.scrollTo({
            top: pageHeight * 4,
            left: 0,
            behavior: "smooth",
          });
          props.setMenu(false);
          console.log(pageHeight * 6)
        } else if (scrollTop >= pageHeight && scrollTop < pageHeight * 5.7){
          // 현재 7페이지
          outerDivRef.current.scrollTo({
            top: (pageHeight * 5),
            left: 0,
            behavior: "smooth",
          });
          props.setMenu(false);
        }
      }
    };
    const outerDivRefCurrent = outerDivRef.current;
    outerDivRefCurrent.addEventListener("wheel", wheelHandler);
    return () => {
      outerDivRefCurrent.removeEventListener("wheel", wheelHandler);
    };
  }, []);

  return (
    <>
      <Wrapper ref={outerDivRef}>
          <BannerContainer/>
          <ShortUrlDescription/>
          <MySortiContainer/>
          <LinkReportContainer/>
          <ExtensionContainer/>
          <StartSortiContainer/>
          <Footer/>
      </Wrapper>
    </>
  )
}

export default Main