import React, {useState, useEffect} from 'react';
import {Route ,Routes ,useLocation} from 'react-router-dom';
import Main from './pages/Main';
import Login from './pages/Login';
import Signup from './pages/Signup';
import MenuBar from './components/MenuBar';
import MenuBarMobile from './components/MenuBarMobile';

const width = window.innerWidth;
const isMobile = width < 600;

function App() {

  const [menuBarState , setMenuBarState] = React.useState(true);

  const location = useLocation();


  return (
    <>
      {isMobile ? <MenuBarMobile/> : <MenuBar top={menuBarState}/>}
      <Routes key={location.pathname} location={location}>
        <Route path='/' exact element={<Main setMenu={setMenuBarState}/>}/>
	<Route path='/index' exact element={<Main setMenu={setMenuBarState}/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/signup' element={<Signup/>}/>
      </Routes>
    </>
  );
}

export default App;
