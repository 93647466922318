import React from 'react'
import styled from 'styled-components';
import * as color from '../css/ColorVariables';

const ButtonWrapper = styled.button`
    color : ${color.whiteColor};
    font-family : suitMedium;
    cursor: pointer;
    border : none;
    font-size : 16px;
    border-radius : 10px;
    background-color : ${props => props.hover ? color.hoverSelectedButton : color.mainColor};
    transform : ${props => props.hover ? 'scale(0.95)' : ''};
    transition : 0.3s;
    padding : 1rem 2rem;
    box-shadow: ${color.mainColorShadow};
`

function SortiButton(props) {

    const [onHover , setOnHover] = React.useState(false);

    const handleOnHover = () => {
        setOnHover(!onHover);
    }

  return (
    <ButtonWrapper onClick={props.onClick} hover={onHover} onMouseEnter={() => handleOnHover()} onMouseOut={() => handleOnHover()}>
        {props.text}
    </ButtonWrapper>
  )
}

export default SortiButton