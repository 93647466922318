import React from 'react'
import styled from 'styled-components'
import * as color from '../css/ColorVariables';

import Extension from '../asset/img/extension.png';
import SortiButton from './SortiButton';

const width = window.innerWidth;
const isMobile = width < 600;

const ExtensionContainerWrapper = styled.div`
    display : flex;
    flex-direction : ${isMobile ? 'column' : 'row'};
    justify-content : center;
    align-items : center;
    padding-top : ${isMobile ? '10%' : '3%'};
    height : 100vh;
    column-gap: 7%;
    row-gap : 7%;
    background-color: ${color.whiteColor};
    & > img {
        width : ${isMobile ? '90%' : '40%'};
    }
    & > div {
        display : flex;
        flex-direction : column;
        align-items :  ${isMobile ? 'center' : 'flex-start'};
        row-gap : ${isMobile ? '30px' : '60px'};
        padding-top : 1%;
        & > p {
            font-family : suitExBold;
            font-size : ${isMobile ? '24px' : '40px'};
            line-height: ${isMobile ? '30px' : '50px'};
            text-align : ${isMobile ? 'center' : ''};
            & > strong {
                font-weight : normal;
                color : ${color.mainColor}
            }
        }
        & > span{
            font-family : suitMedium;
            font-size : ${isMobile ? '14px' : '20px'};
            line-height : ${isMobile ? '18px' : '25px'};
            text-align : ${isMobile ? 'center' : ''};
            color : ${color.darkgrayColor}
        }
    }
`

function ExtensionContainer() {

  return (
    <ExtensionContainerWrapper>
        <img src={Extension} alt={''}/>
        <div>
            <p><strong>브라우저 익스텐션을</strong> 통해<br/>짧은 URL을 바로 생성해보세요</p>
            <span>브라우저 익스텐션을 설치하여<br/>긴 URL을 바로 짧은 URL로 바꿔보세요.</span>
            <SortiButton text={'SORTI 브라우저 익스텐션 설치하기 >'}/>
        </div>
    </ExtensionContainerWrapper>
  )
}

export default ExtensionContainer