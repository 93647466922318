import React from 'react'
import styled from 'styled-components';
import SortiButton from './SortiButton';
import * as color from '../css/ColorVariables';

import Background from '../asset/img/sortiStartBack.png';
import BackgroundMobile from '../asset/img/BackMobile.png';

const width = window.innerWidth;
const isMobile = width < 600;

const StartSortiWrapper = styled.div`
    position : relative;
    height : 100vh;
    text-align : center;
    background : url(${isMobile ? BackgroundMobile : Background});
    background-size : cover;
    & > div {
      position : absolute;
      width : ${isMobile ? '80%' : '40%'};
      top : 45%;
      left : 50%;
      right : 50%;
      bottom : 50%;
        transform: translate(-50%, -50%);
      & > p {
          font-family : suitSemiBold;
          color : ${color.darkgrayColor};
          padding-bottom : 30px;

          font-size : ${isMobile ? '14px' : '20px'};
            line-height : ${isMobile ? '18px' : '25px'};
      }
    }
`

function StartSortiContainer() {


  const goToSignUp = () => {
      window.location.href = '/signup';
  }


  return (
    <StartSortiWrapper>
        <div>
          <p>
              짧은 URL 어쩌구 저쩌구 어쩌구 저쩌구<br/>
              짧은 URL 어쩌구 저쩌구 어쩌구 저쩌구ㅌㄴㅁㄴㅇ
          </p>
          <SortiButton onClick={goToSignUp} text={'SORTI 시작하기'}/>
        </div>
    </StartSortiWrapper>
  )
}

export default StartSortiContainer