import React from 'react'
import styled from 'styled-components'
import * as color from '../css/ColorVariables';

const FooterWrapper = styled.div`
    background-color: ${color.whiteColor};
    padding : 0 10%;
    height : 40vh;
    & > p {
        font-family : suitSemiBold;
        color : ${color.darkgrayColor};
    }
`

function Footer() {
  return (
    <FooterWrapper>
        <p>(주) 솔티랩</p>
    </FooterWrapper>
  )
}

export default Footer