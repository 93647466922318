import React, { useEffect } from 'react'
import styled from 'styled-components'
import SortiButton from './SortiButton'
import * as color from '../css/ColorVariables';

import Background from '../asset/img/mainBack.png';
import BackgroundMobile from '../asset/img/mainBackMobile.png';

const width = window.innerWidth;
const isMobile = width < 600;

const BannerContainerWrapper = styled.div`
    position : relative;
    overflow : hidden;
    height :  ${isMobile ? '80vh' : '100vh'};
    padding-top : ${isMobile ? '50%' : ''};
    background-color : ${color.mainColor};
    & > video {
        transform: ${isMobile ? 'scale(1.4)' : ''};
    }
    & > div {
        display : flex;
        flex-direction : column;
        row-gap : ${isMobile ? '6%' : '10%'};
        justify-content : flex-end;
        align-items : center;
        width : 100%;
        height : 100%;
        position : absolute;
        z-index : 12;
        left : 50%;
        right : 50%;
        bottom : ${isMobile ? '20%' : '7.5%'};
        transform: translate(-50%, 0);
        & > p {
            font-family : suitSemiBold;
            font-size: ${isMobile ? '16px' : '20px'};
            line-height: 160%;
            padding : ${isMobile ? '0 5%' : '0 30%'};
            color: ${color.whiteColor};
        }
    }
`

const Overlay = styled.div`
    position : absolute;
    z-index : 6 !important;
    width : 90%;
    bottom : 0 !important;
    height : ${isMobile ? '40% !important' : '25% !important'};
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%,  rgba(255,255,255,1) 100%);
`

const BackgroundImg = styled.div`
    position : absolute;
    z-index : 5!important;
    top : ${isMobile ? '20px' : '80px'} ;
    background : url(${isMobile ? BackgroundMobile : Background});
    background-repeat : no-repeat;
    background-size : ${isMobile ? 'contain' : 'cover'};
`

function BannerContainer() {

    const goToSignUp = () => {
        window.location.href = '/signup';
    }

  return (
    <>
        <BannerContainerWrapper>
            <video width="100%" height="auto" autoPlay muted loop playsInline>
                {
                    window.navigator.userAgent.includes('iPhone') ?
                    <source src="./Videos/mainBanner.mp4" type="video/mp4"/>
                    :
                    <source src="./Videos/mainBanner.webm" type="video/webm"/>
                }
            </video>
            <div>
                <p>
                    SORTI로 긴 URL을 쉽게 줄이고, 다양한 인사이트들을 확인해보세요. 어쩌구 저쩌구. 저쩌구 SORTI로 긴 URL을 쉽게 줄이고, 다양한 인사이트들을 확인해
                </p>
                <SortiButton onClick={goToSignUp} text='SORTI 시작하기'/>
            </div>
            <Overlay/>
            <BackgroundImg/>
        </BannerContainerWrapper>
    </>
  )
}

export default BannerContainer;