import React, { useEffect } from 'react'
import styled from 'styled-components';
import SideDrawer from "./SideDrawer";
import * as color from '../css/ColorVariables';

import SortiLogo from '../asset/img/sortiLogo.png';
import Hamburger from '../asset/img/hamburger.png';

const Wrapper = styled.div`
    display : flex;
    width : 86%;
    position : fixed;
    z-index : 1000;
    font-family : suitBold;
    align-items : center;
    justify-content : space-between;
    padding : 5% 7%;
    background: white; 
    box-shadow: ${props => props.top ? '' : '0px 1px 20px #D0E3DC'};
    & > img {
        height : 20px;
    }
`

function MenuBarMobile() {
  const [isSideMenuOpen , setIsSideMenuOpen] = React.useState(false);

  const handleSideMenu = () => {
    setIsSideMenuOpen(!isSideMenuOpen);
  }

  const goToMain = () => {
    window.location.href = '/';
  }

  return (
    <Wrapper>
        <img src={SortiLogo} alt='' onClick={goToMain}/>
        <img src={Hamburger} alt='' onClick={handleSideMenu}/>
        <SideDrawer toggle={isSideMenuOpen} toggleFunction={handleSideMenu}/>
    </Wrapper>
  )
}

export default MenuBarMobile